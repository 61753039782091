import React from "react";
import { Divider, VStack } from "@chakra-ui/react";
import { Quiz } from "../../componentes/quiz";
import { motion } from "framer-motion";

export function QuizThree() {
  return (             
    <VStack maxW={900} mx="auto" px={2} align="flex-start">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{width: '100%'}}
      >
        <Quiz 
          number={11}
          title="Um ótimo dia para mim é quando..."
          responseOne={{ value: "A", text: "Consigo fazer muitas coisas" }}
          responseTwo={{ value: "C", text: "Me divirto com meus amigos" }}
          responseThree={{ value: "O", text: "Tudo segue conforme planejado" }}
          responseFour={{ value: "I", text: "Desfruto de coisas novas e estimulantes" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={12}
          title="Eu vejo a morte como..."
          responseOne={{ value: "I", text: "Uma grande aventura misteriosa" }}
          responseTwo={{ value: "C", text: "Oportunidade para rever os falecidos" }}
          responseThree={{ value: "O", text: "Um modo de receber recompensas" }}
          responseFour={{ value: "A", text: "Algo que sempre chega muito cedo" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={13}
          title="Minha filosofia de vida é..."
          responseOne={{ value: "A", text: "Há ganhadores e perdedores, e eu acredito ser um ganhador" }}
          responseTwo={{ value: "C", text: "Para eu ganhar, ninguém precisa perder" }}
          responseThree={{ value: "O", text: "Para ganhar, é preciso seguir regras" }}
          responseFour={{ value: "I", text: "Para ganhar, é necessário inventar novas regras" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={14}
          title="Eu sempre gostei de..."
          responseOne={{ value: "I", text: "Explorar" }}
          responseTwo={{ value: "O", text: "Evitar surpresas" }}
          responseThree={{ value: "A", text: "Focalizar a meta" }}
          responseFour={{ value: "C", text: "Realizar uma abordagem natural" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={15}
          title="Eu gosto de mudanças se..."
          responseOne={{ value: "A", text: "Me der uma vantagem competitiva" }}
          responseTwo={{ value: "C", text: "For divertido e puder ser compartilhado" }}
          responseThree={{ value: "I", text: "Me der mais liberdade e variedade" }}
          responseFour={{ value: "O", text: "Melhorar ou me der mais controle" }}
        />
      </motion.div>            
    </VStack>  
  );
}
