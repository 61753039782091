import React, { ReactNode, useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthProvider/useAuth';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user, checkAuth } = useAuth(); // Obtém o usuário e a função de verificação de autenticação
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento

  useEffect(() => {
    const checkAuthentication = async () => {
      await checkAuth(); // Verifica a autenticação
      setLoading(false); // Define o carregamento como falso após a verificação
    };

    checkAuthentication(); // Chama a função de verificação de autenticação quando o componente monta
  }, []); // O array de dependências está vazio para garantir que o useEffect execute apenas na montagem

  if (loading) {
    // Exibe um carregamento ou null enquanto a autenticação está sendo verificada
    return null; // Você pode retornar um componente de loading aqui se quiser
  }

  // Se o usuário estiver autenticado, renderiza os filhos, caso contrário, redireciona
  return user !== null ? <>{children}</> : <Navigate to="/my-tests" />;
};

export default PrivateRoute;
