import { Flex, Image, Text } from "@chakra-ui/react"

interface Props{
    image:string
    title:string
    url:string
}

export function CardBlog({image, title, url}:Props){

    return(
        <Flex
            minWidth="320px"
            maxWidth= "411px"
            flexDirection= "column"
            alignItems= "flex-start"
            flex= "1 0 0"
            borderRadius= "16px"
            border= "1px solid var(--Gray-200, #EAECF0)"
            background= "var(--Base-White, #FFF)"
        >
            
            
            <img height="240px" style={{
                alignSelf: "stretch",
                borderTopLeftRadius:"16px",
                borderTopRightRadius:"16px"
                }} src={image} alt={title} />
            <Flex 
                padding="24px"
                flexDirection="row"
                alignItems="flex-start"
                gap="24px"
                justifyContent={"space-between"}
                alignSelf="stretch"
                w="100"
            >
                <Text
                    color="#101828"
                    fontSize="24px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="32px"
                >{title}</Text>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 17L17 7M17 7H7M17 7V17" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </Flex>
        </Flex>
    )
}