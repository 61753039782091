import React from 'react';
import { Router } from './routes';
import { BrowserRouter } from 'react-router-dom';
import { QuestionsProvider } from './contexts/QuestionsProvider';
import { AuthProvider } from './contexts/AuthProvider';

function App() {
  return (

    <BrowserRouter> 
      <AuthProvider>
        <QuestionsProvider> 
          <Router />
        </QuestionsProvider>  
      </AuthProvider>      
    </BrowserRouter>
  );
}

export default App;
