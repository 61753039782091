import React, { ReactNode } from "react";
import { Box, HStack,  Stack, Text } from "@chakra-ui/react";
interface NavSectionProps {
  title: string;
  children: ReactNode;
}

export function NavSection({ title, children}: NavSectionProps) {
  
  return (

      <Box>
        <HStack p={1} rounded={5} >
          <Text fontWeight='bold' color='black' fontSize='105%' >{title}</Text> 
        </HStack>
        
        <Stack spacing='2' ml={4} mt='2' mb={1} align='stretch' >
          {children}
        </Stack>      
      </Box>
  )
}