
import  api  from "../../services/apiServer"
import { IFormUser, IRegisterTest } from "./types"

export async function registerTest(data: IRegisterTest) {
  try {
    const request = await api.post("/register-test", data)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}

export async function getMyTests(page: string) {
  try {
    const request = await api.get(`/find-my-test/${page}`)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}

export async function getIndication() {
  try {
    const request = await api.get(`/indication`)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}
export async function findManyTestByUsers(form: IFormUser) {
  try {
    const request = await api.post(`/find-test-by-users`, form)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}