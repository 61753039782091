import React from "react"
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Stack, useBreakpointValue, } from "@chakra-ui/react";
import { Logo } from "../Header/Logo";
import { SidebarNav } from "./SidebarNav";

interface PropsIsOpen{
  isOpen?: boolean
  sendInfo?: ()=> void
}

export function Sidebar({isOpen, sendInfo}:PropsIsOpen) {

  
 
  const isDrawerSidebar = useBreakpointValue({
    base: true,
    lg: true
  })
  if (isDrawerSidebar) {

    

    return (
    <Drawer isOpen={isOpen?isOpen:false} placement="left" onClose={sendInfo?sendInfo:()=>{}}>
      <DrawerOverlay />
        <DrawerContent >
          <DrawerCloseButton mt='3' />
          <DrawerHeader  >
            <Logo />
          </DrawerHeader>
          <DrawerBody  p='4' >
            <SidebarNav />
          </DrawerBody>
        </DrawerContent>
    </Drawer>)
  }
  return (
    <Box
      as='aside'
      p='4'
      zIndex={2}
      rounded={8}
    >
      <SidebarNav />
    </Box >
  )
}