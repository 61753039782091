import React, { useState } from "react";
import { Template } from "../componentes/template";
import {
  VStack,
  Link,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
  Flex,
  Box,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";

type SignInFormData = {
  email: string;
  senha: string;
};

export default function SignIn() {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => setShowPassword(!showPassword);
  const toast = useToast();
  const navigate = useNavigate();
  const { authenticate } = useAuth();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("E-mail obrigatório")
      .email("Insira um e-mail válido"),
    senha: yup.string().required("Senha obrigatória"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SignInFormData>({
    resolver: yupResolver(schema),
  });

  const handleSignIn: SubmitHandler<SignInFormData> = async (values) => {
    setIsLoading(true);
    try {
      await authenticate(values.email, values.senha);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Template isPrint>
      <Flex as="form" w="100%" h="100%" onSubmit={handleSubmit(handleSignIn)}>
        <Box w="100%" mt="4rem" h="100%">
          <VStack
            flex={1}
            maxW={900}
            w="380px"
            mx="auto"
            align={"center"}
            px={5}
          >
            <Text
              fontSize={"3xl"}
              justifyContent="center"
              alignItems="center"
              fontWeight={"bold"}
            >
              Login
            </Text>
            <Text fontSize={"sm"} mb={7}>
              Acesse sua conta
            </Text>
            <FormControl isRequired mb={2}>
              <Text fontSize={"sm"} mb="0.5rem" fontWeight={"400"}>
                E-mail
              </Text>
              <Input
                type="email"
                id="email"
                {...register("email")}
                onChange={(e) =>
                  setValue("email", e.target.value, { shouldValidate: true })
                }
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired>
              <Text fontSize={"sm"} mb="0.5rem" fontWeight={"400"}>
                Senha
              </Text>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  id="senha"
                  {...register("senha", {
                    required: "Senha obrigatória",
                    minLength: 4,
                  })}
                  onChange={(e) =>
                    setValue("senha", e.target.value, { shouldValidate: true })
                  }
                />
                <InputRightElement width="3rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    mt="0.5rem"
                    mr="0px"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    onClick={handleClick}
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
                <FormErrorMessage>{errors.senha?.message}</FormErrorMessage>
              </InputGroup>
            </FormControl>

            <Button
              mt={5}
              bg="#0B4A7D"
              color="white"
              _hover={{ bg: "#1A69A4" }}
              p={6}
              w="full"
              rounded={12}
              fontSize="md"
              isLoading={isLoading}
              type="submit"
              transition="background-color 0.3s ease"
            >
              Entrar
            </Button>
          </VStack>
        </Box>
      </Flex>
    </Template>
  );
}
