import React, { useEffect, useState } from "react";
import {
  VStack,
  Text,
  Button,
  Center,
  Divider,
  Stack
} from "@chakra-ui/react";
import { identificarCampoComMaiorNumero } from "../../utils/identificarCampoComMaiorNumero";
import { identificarLetraComMaiorNumero } from "../../utils/identificarLetraComMaiorNumero";
import { Template } from "../../componentes/template";


// Lazy load components
const BrainParts = React.lazy(() => import("./componentsResult/brainParts"));
const BrainAnalysis = React.lazy(() => import("./componentsResult/brainAnalysis"));
const BrainChart = React.lazy(() => import("./componentsResult/brainChart"));
const PerfilText = React.lazy(() => import("./componentsResult/perfilText"));


export function Pdf() {
  const resultLocal = localStorage.getItem("@result-test")
  const result: any = JSON.parse(resultLocal||"")
  useEffect(() => {
    const timeout = setTimeout(() => {
      gerarPDF()
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);
  

  const gerarPDF = () => {
    window.print();
  };
  
  const x = identificarCampoComMaiorNumero([result])

  return (
    <Template title=" " isPrint>
        <VStack maxW={900} mx="auto" px={0} >
          <Center flexDirection={"column"} >
            <Text textAlign="center" fontWeight="400">
              Seu perfil comportamental é
            </Text>
            <Text textAlign="center" fontSize={"2xl"} fontWeight="763">
              {x&&x[0].perfil}
            </Text>
          </Center>
          <VStack fontSize={"md"} w="100%" flexDirection='column'>
            {/* <Divider mb={10} /> */}
            <PerfilText letra={identificarLetraComMaiorNumero(result)} />
            {/* <OverView result={result} /> */}
            <div style={{ pageBreakBefore: 'always' }}> 
              <BrainParts result={result} print />
              <BrainAnalysis result={result} />
              <BrainChart result={result} />
            </div>
          </VStack>
        </VStack>
    </Template>
  );
}
