export function identificarPerfil(letra:string) {
    switch (letra) {
      case "I":
        return "Influente"
        
      case "C":
        return "Estável"
        
      case "A":
        return "Dominante"
        
      case "O":
        return "Analítico"
                  
        
      default:
        return ""
    }
  }

