import React from "react";
import {
  Flex,
  HStack,
  Icon,
  IconButton,
  useBreakpointValue,
  Button,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { IoLogOutOutline } from "react-icons/io5";
import { RiMenuLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

import { Logo } from "./Logo";
import { Profile } from "./Profile";

interface PropsDrawer {
  sendInfo: () => void;
  isPrint?: boolean;
}
interface ErroType {
  response?: {
    data: {
      message: string;
    };
  };
}

export function Header({ sendInfo, isPrint }: PropsDrawer) {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  // Função para pegar o primeiro nome e capitalizar a primeira letra
  const getFirstName = (fullName: string) => {
    if (!fullName) return "";
    const firstName = fullName.split(" ")[0]; // Pega o primeiro nome
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase(); // Capitaliza a primeira letra
  };
  const { user, logout } = useAuth(); // Obtém a função de logout do contexto
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = () => {
    logout(); // Executa o logout do usuário
    navigate("/sign-in"); // Redireciona para a página de login
  };
  return (
    <>
      <Flex
       as="header"
       position="fixed"
       w="100%"
       top={0}
       zIndex={999}
       bg={{base: "rgba(0, 0, 0, 0.05)"}}  // Cor de fundo transparente com leve opacidade
       backdropFilter={{base: "blur(10px)"}}     // Aplicando o blur
       align="center"
       justifyContent="space-between"
      >
        <HStack>
          {!isWideVersion && !isPrint && (
            <IconButton
              aria-label="Open navigation"
              icon={<Icon as={RiMenuLine} />}
              fontSize="24"
              variant="unstyled"
              onClick={sendInfo}
              mr="2"
            ></IconButton>
          )}
          <Flex ml="1rem" p="0.5rem">
            <Logo />
          </Flex>
        </HStack>

        {(location.pathname === "/" || location.pathname === "/sign-up") && (
          <Button
            onClick={() => navigate("/sign-in")}
            mr="3rem"
            border="1px solid white"
            rounded="14px"
            fontSize="1rem"
            fontWeight="sm"
            w="12rem"
            h="2.5rem"
            p="1rem"
            bg="transparent"
            transition="background-color 0.3s ease"
          >
            Acessar minha conta
          </Button>
        )}
        {location.pathname === "/sign-in" && (
          <Button
            onClick={() => navigate("/sign-up")}
            mr="2rem"
            rounded="14px"
            fontSize="1rem"
            fontWeight="500"
            w="10rem"
            h="2.5rem"
            color="white"
            _hover={{ bg: "#1A69A4" }}
            p="1rem"
            bg="#0B4A7D"
            transition="background-color 0.3s ease"
          >
            Cadastrar-se
          </Button>
        )}

        {isWideVersion &&
          !isPrint &&
          (user?.is_admin ? (
            <HStack>
              <ButtonMenu title="Início" url="/admin/home" />
              <ButtonMenu title="Criações" url="/" />
            </HStack>
          ) : (
            <HStack>{/* <ButtonMenu title="Início" url="/home" />   */}</HStack>
          ))}

        {user && user.id && (
          <HStack mr="1rem">
            <Button
              gap="0.3rem"
              onClick={handleLogout}
              bg="transparent"
              color="#FA796F"
              _hover={{ bg: "transparent", color: "#FA798F" }}
            >
              Sair
              <IoLogOutOutline />
            </Button>
          </HStack>
        )}
      </Flex>

      {isWideVersion && !isPrint && user && (
        <Flex>
          {/* Exibe a saudação com o nome do usuário */}
          <Text
            fontSize="1rem"
            fontWeight="600"
            color="white"
            textAlign="center"
            mt="1.5rem"
          >
            Seja bem-vindo(a), {getFirstName(user.nome_completo)} 👋
          </Text>
        </Flex>
      )}
    </>
  );
}

interface PropsButton {
  title: string;
  url: string;
}

function ButtonMenu({ title, url }: PropsButton) {
  const Location = useLocation();
  const navigate = useNavigate();
  return (
    <Button
      display="flex"
      h="36px"
      p="0px 16px"
      justifyContent="flex-end"
      alignItems="center"
      gap="10px"
      background={Location.pathname === url ? "#E5ECFF" : "#FFF"}
      color={Location.pathname === url ? "#406DFD" : "#667085"}
      onClick={() => navigate(url)}
    >
      {title}
    </Button>
  );
}
