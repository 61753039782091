import React, { useEffect, useState } from "react";
import { Text, RadioGroup, Stack, Radio, Box } from "@chakra-ui/react";
import { useQuestions } from "../../contexts/QuestionsProvider/useQuiz";


interface Props{
    number: number
    title: string
    answered?:  (status: boolean) => void;
    responseOne: Response
    responseTwo: Response
    responseThree: Response
    responseFour: Response
}

interface Response{
    value: string
    text: string
}

export function Quiz({
    number, title, answered, responseOne, responseTwo, responseThree, responseFour
}: Props){
    const {responseQuestions} = useQuestions()
    const [value,setValue] = useState(false)
    const handle = (e: string)=>{
        setValue(true)
        localStorage.setItem(String(number), e)
        responseQuestions(number, e)
    }
    
    return(
        <Box opacity={value ? 0.3 : 1} transition={"1s linear"}>
            <Text fontWeight='bold' mb={2}>
                {number} {title}
            </Text>

            <RadioGroup onChange={handle} >
                <Stack>
                    <Radio value={responseOne.value}>{responseOne.text}</Radio>
                    <Radio value={responseTwo.value}>{responseTwo.text}</Radio>
                    <Radio value={responseThree.value}>{responseThree.text}</Radio>
                    <Radio value={responseFour.value}>{responseFour.text}</Radio>
                </Stack>

            </RadioGroup>
        </Box>
    )
}