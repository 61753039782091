import React from "react"
import { HStack, Icon, Link as ChakraLink, LinkProps, Text } from "@chakra-ui/react";
import { ElementType, ReactNode } from "react";
import { Link, NavLink  as Nav} from "react-router-dom";

interface NavLinkProps extends LinkProps {
  icon: ElementType;
  children: ReactNode;
  href: string;
}

export function NavLink({ icon, children, href, ...rest }: NavLinkProps) {
    let activeStyle = {
    textDecoration: "underline",
  };

  let activeClassName = "underline";
  return (
      <ChakraLink display='flex' alignItems='center' {...rest} color="gray.400">
        <Icon as={icon} fontSize='20' mr={2} />      
        <Link to={href}>  
          <HStack>{children}</HStack>        
        </Link>          
      </ChakraLink>
      

  );
}