import React from "react";
import { Divider, VStack } from "@chakra-ui/react";
import { Quiz } from "../../componentes/quiz";
import { motion } from "framer-motion";

export function QuizTwo() {
  return (             
    <VStack maxW={900} mx="auto" px={2} align="flex-start">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{width: '100%'}}
      >
        <Quiz 
          number={6}
          title="Eu penso que..."
          responseOne={{ value: "C", text: "Unidos venceremos, divididos perderemos" }}
          responseTwo={{ value: "A", text: "O ataque é melhor que a defesa" }}
          responseThree={{ value: "I", text: "É bom ser manso, mas andar com um porrete" }}
          responseFour={{ value: "O", text: "Um homem prevenido vale por dois" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={7}
          title="Minha preocupação é..."
          responseOne={{ value: "I", text: "Gerar a ideia global" }}
          responseTwo={{ value: "C", text: "Fazer com que as pessoas gostem" }}
          responseThree={{ value: "O", text: "Fazer com que funcione" }}
          responseFour={{ value: "A", text: "Fazer com que aconteça" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={8}
          title="Eu prefiro..."
          responseOne={{ value: "I", text: "Perguntas e respostas" }}
          responseTwo={{ value: "O", text: "Ter todos os detalhes" }}
          responseThree={{ value: "A", text: "Vantagens a meu favor" }}
          responseFour={{ value: "C", text: "Que todos tenham a chance de serem ouvidos" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={9}
          title="Eu gosto de..."
          responseOne={{ value: "A", text: "Fazer progresso" }}
          responseTwo={{ value: "C", text: "Construir memórias" }}
          responseThree={{ value: "O", text: "Fazer sentido" }}
          responseFour={{ value: "I", text: "Tornar as pessoas confortáveis" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={10}
          title="Eu gosto de chegar..."
          responseOne={{ value: "A", text: "Na frente" }}
          responseTwo={{ value: "C", text: "Junto" }}
          responseThree={{ value: "O", text: "Na hora" }}
          responseFour={{ value: "I", text: "Em outro lugar" }}
        />
      </motion.div>                          
    </VStack>  
  );
}
