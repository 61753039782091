export function identificarPontoForte(letra: string) {
  switch (letra) {
    case "I":
      return "Idealização";

    case "C":
      return "Comunicação";

    case "A":
      return "Entrar em ação";

    case "O":
      return "Organização estratégica";


    default:
      return "";
  }
}
