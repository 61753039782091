
import Cookies from "js-cookie"
import  api  from "../../services/apiServer"
import { IFormUser, INewUser, IPayLoad } from "./types"

export async function LoginRequest(email: string, password: string) {
  try {
    const request = await api.post("/auth", { email, password })
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}
export async function findManyUsers(form: IFormUser) {
  try {
    const request = await api.post(`/user/find-many`, form)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}
export async function createUser(form: INewUser) {
  try {
    const request = await api.post(`/user/create`, form)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}
export function setUserLocalStorage(data: IPayLoad | null) {
  
  Cookies.set('@PerfilComportamental-Data', JSON.stringify(data), {
    expires: 30/1440*2*6  
  })
  if(data&&data.token){
    Cookies.set("@PerfilComportamental-Token", data.token, {
      expires: 30/1440*2*6    
    })
  }
}
export function getUserLocalStorage() {

  const json = Cookies.get("@PerfilComportamental-Data")  

  if (!json) {
    return null
  }

  const user = JSON.parse(json)

  return user ?? null
}