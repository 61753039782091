import React, { useState } from "react";
import { Template } from "../componentes/template";
import {
  VStack,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  Flex,
  Box,
  extendBaseTheme,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";

import { INewUser } from "../contexts/AuthProvider/types";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const schema = yup.object().shape({
  nome_completo: yup.string().required("Nome é obrigatório"),
  nascimento: yup.string().required("Data de nascimento é obrigatória"),
  sexo: yup.string().required("Sexo é obrigatório"),
  fone: yup.string().required("Contato é obrigatório"),
  ocupacao: yup.string().required("Ocupação é obrigatória"),
  email: yup.string().required("E-mail é obrigatório").email("E-mail inválido"),
  senha: yup.string().required("Senha é obrigatória"),
  confirmeSenha: yup
    .string()
    .required("Confirmação de senha é obrigatória")
    .oneOf([yup.ref("senha")], "Senhas devem coincidir") as yup.StringSchema<
    string | undefined,
    FieldValues
  >,
});

export default function SignUp() {
  const navigate = useNavigate();
  const { novoUser } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickPassword = () => setShowPassword(!showPassword);
  const handleClickConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const toast = useToast();
  const { register, handleSubmit, formState, setValue } = useForm<INewUser>({
    resolver: yupResolver(schema) as any,
  });

  const handleNavigation = () => {
    navigate("/sign-in");
  };

  const onSubmit: SubmitHandler<INewUser> = async (data) => {
    localStorage.setItem("data-perfil-comportamental", JSON.stringify(data));
    console.log(data);
    try {
      await novoUser(data);
      handleNavigation();
    } catch (error) {
      toast({
        position: "top",
        description:
          "Erro ao criar usuário. Verifique suas informações e tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Template isPrint>
      <Flex w="100%" h="100%">
        <Box w="100%" h="100%">
          <VStack
            flex={1}
            maxW={900}
            w="full"
            mx="auto"
            align={"center"}
            px={5}
          >
            <Text
              fontSize={"4xl"}
              justifyContent="center"
              alignItems="center"
              fontWeight={"bold"}
              mb={2}
            >
              Cadastro
            </Text>
            <Text fontSize={"sm"} mb={7}>
              Preencha com seus dados pessoais
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isRequired mb={5}>
                <FormLabel fontSize={"sm"} fontWeight={"400"}>
                  Nome
                </FormLabel>
                <Input
                  placeholder="Digite seu nome"
                  {...register("nome_completo")}
                  onChange={(e) => setValue("nome_completo", e.target.value)}
                />
              </FormControl>
              <FormControl isRequired mb={5}>
                <FormLabel fontSize={"sm"} fontWeight={"400"}>
                  E-mail
                </FormLabel>
                <Input
                  placeholder="Digite seu e-mail"
                  {...register("email")}
                  type="email"
                  onChange={(e) => setValue("email", e.target.value)}
                />
              </FormControl>
              <Flex
                flexDirection={["column", "row"]}
                w="full"
                justify="space-between"
              >
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                  <FormLabel fontSize={"sm"} fontWeight={"400"}>
                    Data de nascimento
                  </FormLabel>
                  <Input
                    placeholder="Digite sua nascimento"
                    {...register("nascimento")}
                    onChange={(e) => setValue("nascimento", e.target.value)}
                    type="date"
                  />
                </FormControl>
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                  <FormLabel fontSize={"sm"} fontWeight={"400"}>
                    Sexo
                  </FormLabel>
                  <Select
                    rounded="12px"
                    border="1px solid rgba(255, 255, 255, 0.20)" // Borda padrão
                    background="rgba(255, 255, 255, 0.10)"
                    h="50px"
                    placeholder="Escolha"
                    {...register("sexo")}
                    onChange={(e) => setValue("sexo", e.target.value)}
                  >
                    <option value="masculino">Masculino</option>
                    <option value="feminino">Feminino</option>
                  </Select>
                </FormControl>
              </Flex>
              <Flex
                flexDirection={["column", "row"]}
                w="full"
                justify="space-between"
              >
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                  <FormLabel fontSize={"sm"} fontWeight={"400"}>
                    Ocupação
                  </FormLabel>
                  <Input
                    {...register("ocupacao")}
                    placeholder="Digite sua ocupação"
                    onChange={(e) => setValue("ocupacao", e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                  <FormLabel fontSize={"sm"} fontWeight={"400"}>
                    Contato
                  </FormLabel>

                  <Input
                    {...register("fone")}
                    onChange={(e) => setValue("fone", e.target.value)}
                  />
                </FormControl>
              </Flex>

              <Flex
                flexDirection={["column", "row"]}
                w="full"
                justify="space-between"
              >
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                  <FormLabel fontSize={"sm"} fontWeight={"400"}>
                    Senha
                  </FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      {...register("senha")}
                      onChange={(e) => setValue("senha", e.target.value)}
                    />
                    <InputRightElement width="3rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        mt="0.5rem"
                        mr="0px"
                        bg="transparent"
                        _hover={{ bg: "transparent" }}
                        _active={{ bg: "transparent" }}
                        onClick={handleClickPassword}
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                  <FormLabel fontSize={"sm"} fontWeight={"400"}>
                    Confirme a senha
                  </FormLabel>
                  <InputGroup>
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      {...register("confirmeSenha")}
                      onChange={(e) =>
                        setValue("confirmeSenha", e.target.value)
                      }
                    />
                    <InputRightElement width="3rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        mt="0.5rem"
                        mr="0px"
                        bg="transparent"
                        _hover={{ bg: "transparent" }}
                        _active={{ bg: "transparent" }}
                        onClick={handleClickConfirmPassword}
                      >
                        {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Flex>
              <Button
                mt={5}
                mb={5}
                bg="#0B4A7D"
                _hover={{ bg: "#1A69A4" }}
                color="white"
                colorScheme="blue"
                p={6}
                w="full"
                rounded={10}
                fontSize="md"
                isLoading={formState.isSubmitting}
                type="submit"
              >
                Registrar
              </Button>
            </form>
          </VStack>
        </Box>
      </Flex>
    </Template>
  );
}
