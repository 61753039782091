export function identificarComportamento(letra: string) {
  switch (letra) {
    case "I":
      return "Fazer diferente";

    case "C":
      return "Fazer junto";

    case "A":
      return "Fazer rápido";

    case "O":
      return "Fazer certo";


    default:
      return "";
  }
}
