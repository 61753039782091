import React from "react";
import { Divider, VStack } from "@chakra-ui/react";
import { Quiz } from "../../componentes/quiz";
import { motion } from "framer-motion";

export function QuizFour() {
  return (
    <VStack maxW={900} mx="auto" px={2} align="flex-start">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{width: '100%'}}
      >
        <Quiz 
          number={16}
          title="Não existe nada de errado em..."
          responseOne={{ value: "A", text: "Se colocar na frente" }}
          responseTwo={{ value: "C", text: "Colocar os outros na frente" }}
          responseThree={{ value: "I", text: "Mudar de ideia" }}
          responseFour={{ value: "O", text: "Ser consistente" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={17}
          title="Eu gosto de buscar conselhos de..."
          responseOne={{ value: "A", text: "Pessoas bem-sucedidas" }}
          responseTwo={{ value: "C", text: "Anciões e conselheiros" }}
          responseThree={{ value: "O", text: "Autoridades no assunto" }}
          responseFour={{ value: "I", text: "Lugares, os mais estranhos" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={18}
          title="Meu lema é..."
          responseOne={{ value: "I", text: "Fazer o que precisa ser feito" }}
          responseTwo={{ value: "O", text: "Fazer bem feito" }}
          responseThree={{ value: "C", text: "Fazer junto com o grupo" }}
          responseFour={{ value: "A", text: "Simplesmente fazer" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={19}
          title="Eu gosto de..."
          responseOne={{ value: "I", text: "Complexidade, mesmo se confuso" }}
          responseTwo={{ value: "O", text: "Ordem e sistematização" }}
          responseThree={{ value: "C", text: "Calor humano e animação" }}
          responseFour={{ value: "A", text: "Coisas claras e simples" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={20}
          title="Tempo pra mim..."
          responseOne={{ value: "A", text: "Algo que detesto desperdiçar" }}
          responseTwo={{ value: "C", text: "Um grande ciclo" }}
          responseThree={{ value: "O", text: "Uma flecha que leva ao inevitável" }}
          responseFour={{ value: "I", text: "Irrelevante" }}
        />
      </motion.div>
    </VStack>  
  );
}
