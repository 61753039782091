
import React, { createContext, useEffect, useState } from "react";
import { IQuestionsProvider, IContext, IRegisterTest, ITests, IDataTests, IIndications, IFormUser, ITestsByUsers } from "./types";
import { findManyTestByUsers, getIndication, getMyTests, registerTest } from "./util";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const QuestionsContext = createContext<IContext>({} as IContext)

export const QuestionsProvider = ({ children }: IQuestionsProvider) => {
  const [questions, setQuestions] = useState<string []>(["","","","","","","","","","","","","","","","","","","","","","","","","",])
  const navigate = useNavigate()
  const toast = useToast()
  const [tests, setTests] = useState<IDataTests|null>(null)
  const [indications, setIndications] = useState<IIndications>({
    idadeMedia: 0,
    perfilMaisRegistrado: "",
    totalUsers: 0,
    porcentagemPerfilMaisRegistrado: 0
  })
  const [ testsByUsers, setTestsByUsers ] = useState<ITestsByUsers []>([])
  const [totalUsers, setTotalUsers ] = useState(0)

  function responseQuestions(index: number, value: string){
    setQuestions((prevQuestions) => {
      // Cria uma cópia do estado atual
      const updatedQuestions =  [...prevQuestions] ;
      
      // Atualiza o valor do item específico com base no índice fornecido
      updatedQuestions[index-1] = value
  
      return updatedQuestions;
    });
  }
 
  const newRegisterTest = async (data: IRegisterTest) => {
    try {
      await registerTest(data)
      setTimeout(() => {
        navigate("/result")
      }, 750);
      myTests("0")
    } catch (error) {
      handleToastError(error);
    }
  };
  const myTests = async (page: string) => {
    try {
      const response = await getMyTests(page)
      setTests(response)
    } catch (error) {
      handleToastError(error);
    }
  };
  const requestIndication = async()=>{  
    try {
      const response = await getIndication()
      setIndications(response)
    } catch (error) {
      handleToastError(error);
    }
  }

  const findTestByUsers = async (form: IFormUser)=> {
    try {
      const response = await findManyTestByUsers(form)
      setTestsByUsers(response.data)
      setTotalUsers(response.total)
    } catch (error) {
      handleToastError(error);
    }
  } 

  
  const handleToastSuccess = (description: string) => {
    toast({
      position: "top",
      description,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const handleToastError = (error: any) => {
    toast({
      position: "top",
      description: error.response.data.message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };
  

  return (
    <QuestionsContext.Provider value={{ 
      questions,
      responseQuestions,
      newRegisterTest,
      myTests,
      tests,
      requestIndication,
      indications,
      findTestByUsers,
      testsByUsers,
      totalUsers
    }}>
      {children}
    </QuestionsContext.Provider>
  )
}