import React, { ReactNode } from "react";
import { Flex, Text } from "@chakra-ui/react";
interface Props {
  title: string;
  children?: ReactNode;
}
export function HeaderContent({ title, children }: Props) {
  return (
    <Flex
      pl="1rem"
      alignItems={"center"}
      gap="12px"
      alignSelf={"stretch"}
      justifyContent="space-between"
      borderRadius="8px"
      bg="transparent"
    >
      <Text
        color="white"
        fontSize="32px"
        fontWeight={600}
        lineHeight="123.529%"
        letterSpacing="-0.68px"
      >
        {title}
      </Text>
      {children && children}
    </Flex>
  );
}
