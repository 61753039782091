import { ITests } from "../contexts/QuestionsProvider/types";


export function identificarLetraComMaiorNumero(objeto: ITests | null): string  {
    if (objeto) {
        const campos: (keyof ITests)[] = ['a_dominante', 'c_estavel', 'i_influente', 'o_analitico'];
        let campoComMaiorNumero: keyof ITests = campos[0];
        let maiorNumero: number = objeto[campoComMaiorNumero] as number;

        campos.forEach(campo => {
            const valorAtual = objeto[campo] as number;
            if (typeof valorAtual === 'number' && valorAtual > maiorNumero) {
                maiorNumero = valorAtual;
                campoComMaiorNumero = campo;
            }
        });

        return campoComMaiorNumero.charAt(0).toLocaleUpperCase();
    } else {
        return "";
    }
}
