import { ITests } from "../contexts/QuestionsProvider/types";
import { identificarPerfil } from "./identificarPerfil";
import { identificarComportamento } from "./identificarComportamento";
import { identificarPontoForte } from "./identificarPontoForte";
import { converterDataFormato } from "./converterDataFormato";
import { ObjetoComMaiorNumero } from "../pages/myTests";


export function identificarCampoComMaiorNumero(objetos: ITests[] | null): ObjetoComMaiorNumero[] | null {
    if (objetos) {
        return objetos.map(objeto => {
            const campos: (keyof ITests)[] = ['a_dominante', 'c_estavel', 'i_influente', 'o_analitico'];
            let campoComMaiorNumero: keyof ITests = campos[0];
            let maiorNumero: number = objeto[campoComMaiorNumero] as number;

            campos.forEach(campo => {
                const valorAtual = objeto[campo] as number;
                if (typeof valorAtual === 'number' && valorAtual > maiorNumero) {
                    maiorNumero = valorAtual;
                    campoComMaiorNumero = campo;
                }
            });

            return {
                id: objeto.id,
                perfil: identificarPerfil(campoComMaiorNumero.charAt(0).toLocaleUpperCase()) as string,
                comportamento: identificarComportamento(campoComMaiorNumero.charAt(0).toLocaleUpperCase()) as string,
                ponto_forte: identificarPontoForte(campoComMaiorNumero.charAt(0).toLocaleUpperCase()) as string,
                date_create: converterDataFormato(objeto.date_create)
            };
        });
    } else {
        return null;
    }
}

