import React, { useEffect, useState } from "react";
import { Template } from "../componentes/template";
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { HeaderContent } from "../componentes/content/header";
import { FiSearch } from "react-icons/fi";
import { BodyContent } from "../componentes/content/body";
import { useQuestions } from "../contexts/QuestionsProvider/useQuiz";
import { identificarCampoComMaiorNumero } from "../utils/identificarCampoComMaiorNumero";
import { ITests } from "../contexts/QuestionsProvider/types";
import { FaPlay, FaPlus } from "react-icons/fa";
export type ObjetoComMaiorNumero = {
  id: string;
  perfil: string;
  comportamento: string;
  ponto_forte: string;
  date_create: string;
};
export function MyTests() {
  const navigate = useNavigate();
  const { myTests, tests } = useQuestions();
  const [page, setPage] = useState(0);
  const [data, setData] = useState<ObjetoComMaiorNumero[] | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    myTests("0");
    setTimeout(() =>
      setData(identificarCampoComMaiorNumero(tests && tests.data))
    );

    myTests("0");
  }, []);

  const handleNavigation = () => {
    navigate("/questions");
  };

  useEffect(() => {
    setData(identificarCampoComMaiorNumero(tests && tests.data));
  }, [tests]);

  function handlePage(page: number) {
    if (page >= 0) myTests(String(page));
  }

  function viewResult(id: string) {
    const x: any = tests?.data.filter((i) => i.id === id);
    const resultData: ITests = x[0];
    navigate("/result", { state: { resultData } });
  }
  return (
    <Template home>
      {showConfirmation ? (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={4}
        >
          <Text color="white" fontWeight={700} fontSize="4xl">
            Teste de Perfil Comportamental
          </Text>
          <Text align="center">
            Em cada uma das 25 questões a seguir, escolha uma alternativa (I, C,
            O ou A) e marque-a no espaço <br /> correspondente.
          </Text>
          <Flex gap={4}>
            <Button
              variant="outline"
              color="white"
              fontSize="sm"
              bg="rgba(255, 255, 255, 0.10);"
              border="none"
              onClick={() => setShowConfirmation(false)} // Voltar para a tabela
            >
              Cancelar
            </Button>
            <Button
              fontSize="sm"
              color="white"
              bg="#0B4A7D"
              gap={"0.5rem"}
              _hover={{ bg: "#0B4A9E" }}
              onClick={handleNavigation}
            >
              <FaPlay />
              Iniciar teste
            </Button>
          </Flex>
        </Flex>
      ) : (
        <>
          <Flex
            p={["0px 10px", "0px 64px"]}
            flexDirection="column"
            alignItems="flex-start"
            gap="24px"
            alignSelf="stretch"
          >
            <HeaderContent title="Meus testes">
              <Button
                bg="#0B4A7D"
                color="white"
                _hover={{ bg: "#1A69A4" }}
                colorScheme="blue"
                rounded={12}
                leftIcon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0003 4.16663V15.8333M4.16699 9.99996H15.8337"
                      stroke="white"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                // onClick={handleNavigation}
                onClick={() => setShowConfirmation(true)}
              >
                Novo teste
              </Button>
            </HeaderContent>
            <BodyContent>
              {tests && tests.data.length > 0 ? (
                <TableContainer
                  display="flex"
                  background="linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(153, 153, 153, 0.1) 100%)"
                  borderRadius="8px"
                  border="1px solid rgba(255, 255, 255, 0.20)"
                  flexDirection="column"
                  alignItems="flex-start"
                  alignSelf="stretch"
                  w="100%"
                  fontSize={["xs", "md"]}
                >
                  <Table variant="simple">
                    {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                    <Thead>
                      <Tr>
                        <Th color="#A0A0A0">Data</Th>
                        <Th color="#A0A0A0">Perfil comportamental</Th>
                        <Th color="#A0A0A0">Dominantes comportamentos</Th>
                        <Th color="#A0A0A0">Ponto forte</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data &&
                        data.length > 0 &&
                        data.map((i) => {
                          return (
                            <Tr>
                              <Td>{i.date_create}</Td>
                              <Td>{i.perfil}</Td>
                              <Td>{i.comportamento}</Td>
                              <Td>{i.ponto_forte}</Td>
                              <Td
                                color="#A0A0A0"
                                fontWeight="normal"
                                cursor={"pointer"}
                                _hover={{ bg: "transparent", color: "white" }}
                                onClick={() => viewResult(i.id)}
                              >
                                Detalhes
                              </Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                    <Tfoot justifyContent="space-between">
                      <Tr>
                        <Th>
                          <Button
                            variant={"outline"}
                            border="1px solid #EAECF0"
                            color="#A0A0A0"
                            rounded={12}
                            onClick={() => {
                              setPage(page - 1);
                              handlePage(page - 1);
                            }}
                            isDisabled={page === 0}
                          >
                            Anterior
                          </Button>
                        </Th>
                        <Th></Th>
                        <Th
                          color="#FFFFFF"
                          fontWeight={500}
                          fontSize="14px"
                          fontStyle="normal"
                        >
                          Página {page + 1} de {Math.ceil(tests.total / 20)}
                        </Th>
                        <Th></Th>
                        <Th>
                          <Button
                            variant={"outline"}
                            border="1px solid #EAECF0"
                            color="#A0A0A0"
                            rounded={12}
                            onClick={() => {
                              setPage(page + 1);
                              handlePage(page + 1);
                            }}
                            isDisabled={
                              Math.ceil(tests.total / 20) === page + 1
                            }
                          >
                            Próximo
                          </Button>
                        </Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              ) : (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  mt="2rem"
                  w="100vw"
                  gap={2}
                >
                  <Button rounded="0.9rem">
                    <FiSearch />
                  </Button>
                  <Text fontSize="2xl" fontWeight="bold" color="white">
                    Nenhum teste encontrado
                  </Text>
                  <Text fontSize="md" textAlign="center">
                    Faça um teste de personalidade clicando <br />
                    no botão abaixo.
                  </Text>
                  <Button
                    bg="#0B4A7D"
                    color="white"
                    _hover={{ bg: "#1A69A4" }}
                    colorScheme="blue"
                    rounded={12}
                    leftIcon={<FaPlus />}
                    onClick={() => setShowConfirmation(true)}
                  >
                    Novo Teste
                  </Button>
                </Flex>
              )}
            </BodyContent>
          </Flex>
        </>
      )}
    </Template>
  );
}
