export function calcularIdade(dataNascimento: Date): number {
    const agora = new Date();
    const anoAtual = agora.getFullYear();
    const anoNascimento = dataNascimento.getFullYear();
  
    const idade = anoAtual - anoNascimento;
  
    // Verifica se o aniversário já ocorreu neste ano
    if (
      agora.getMonth() < dataNascimento.getMonth() ||
      (agora.getMonth() === dataNascimento.getMonth() &&
        agora.getDate() < dataNascimento.getDate())
    ) {
      // Se ainda não teve aniversário, subtrai 1 da idade
      return idade - 1;
    } else {
      return idade;
    }
}