import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});

const token = Cookies.get("@PerfilComportamental-Token");

api.interceptors.request.use((config) => {
  if (!config.url?.includes('/auth')) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    const newToken = response.headers['new-token']; // Substitua 'new-token' pelo nome do header que contém o novo token

    if (newToken) {
      // Atualizar o token no cookie
      Cookies.set("@PerfilComportamental-Token", newToken);
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
