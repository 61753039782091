import React from "react";
import { Divider, VStack } from "@chakra-ui/react";
import { Quiz } from "../../componentes/quiz";
import { motion } from "framer-motion";

export function QuizOne() {
  return (
    <VStack maxW={900} mx="auto" px={2} align="flex-start">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{width: '100%'}}
      >
        <Quiz
          number={1}
          title="Eu sou..."
          responseOne={{
            value: "I",
            text: "Idealista, criativo e visionário",
          }}
          responseTwo={{
            value: "C",
            text: "Divertido, espirituoso e benevolente",
          }}
          responseThree={{
            value: "O",
            text: "Confiável, meticuloso e previsível",
          }}
          responseFour={{
            value: "A",
            text: "Focado, determinado e persistente",
          }}
        />
        <Divider my={5} w="100%" />
        <Quiz
          number={2}
          title="Eu gosto de..."
          responseOne={{
            value: "A",
            text: "Ser piloto",
          }}
          responseTwo={{
            value: "C",
            text: "Conversar com os passageiros",
          }}
          responseThree={{
            value: "O",
            text: "Planejar a viagem",
          }}
          responseFour={{
            value: "I",
            text: "Explorar novas rotas",
          }}
        />
        <Divider my={5} w="100%" />
        <Quiz
          number={3}
          title="Se você quiser se dar bem comigo..."
          responseOne={{
            value: "I",
            text: "Me dê liberdade",
          }}
          responseTwo={{
            value: "O",
            text: "Me deixe saber suas expectativas",
          }}
          responseThree={{
            value: "A",
            text: "Lidere, siga ou saia do caminho",
          }}
          responseFour={{
            value: "C",
            text: "Seja amigável, carinhoso e compreensivo",
          }}
        />
        <Divider my={5} w="100%" />
        <Quiz
          number={4}
          title="Para obter bons resultados é preciso..."
          responseOne={{
            value: "I",
            text: "Lidar com incertezas",
          }}
          responseTwo={{
            value: "O",
            text: "Controlar o essencial",
          }}
          responseThree={{
            value: "C",
            text: "Ter diversão e celebração",
          }}
          responseFour={{
            value: "A",
            text: "Planejar e obter recursos",
          }}
        />
        <Divider my={5} w="100%" />
        <Quiz
          number={5}
          title="Eu me divirto quando..."
          responseOne={{
            value: "A",
            text: "Estou me exercitando",
          }}
          responseTwo={{
            value: "I",
            text: "Tenho novidades",
          }}
          responseThree={{
            value: "C",
            text: "Estou com outras pessoas",
          }}
          responseFour={{
            value: "O",
            text: "Determino as regras",
          }}
        />
      </motion.div>
    </VStack>
  );
}
