// PublicRoute.tsx
import React, { ReactNode, useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthProvider/useAuth';

interface PublicRouteProps {
  children: ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { user, checkAuth } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      await checkAuth(); // Assumindo que checkAuth é uma função assíncrona que verifica a autenticação
      setLoading(false);
    };

    checkAuthentication();
  }, [checkAuth]);

  if (loading) {
    // Pode exibir um loader ou uma mensagem de carregamento aqui
    return null;
  }

  return user === null ? <>{children}</> : <Redirect /> ;
};

export default PublicRoute;


function Redirect(){
  const { user } = useAuth();
  useEffect(()=>{
    user?.is_admin
    ?window.location.href = "/admin/home"    
    :window.location.href = "/my-tests"
  },[])
  
  return(<></>)
}