import React, { useEffect, useState } from "react";
import { Template } from "../../componentes/template";
import { VStack, Text, Tab, TabList, TabPanel, TabPanels, Tabs, Box, Progress, HStack, Button, useToast, Stack } from "@chakra-ui/react";
import { QuizOne } from "./quizOne";
import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { QuizTwo } from "./quizTwo";
import { QuizThree } from "./quizThree";
import { QuizFour } from "./quizFour";
import { QuizFive } from "./quizFive";
import { useQuestions } from "../../contexts/QuestionsProvider/useQuiz";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider/useAuth";

export function TabQuiz() {
    const [isInvalid, setIsInvalid] = useState(false)
    const {user} = useAuth()
    const {newRegisterTest} = useQuestions()
    const [tabIndex, setTabIndex] = useState(0);
    const {questions, responseQuestions} = useQuestions()
    const navigate = useNavigate()
    const toast = useToast()

    useEffect(()=>{
        const perfil = localStorage.getItem('data-perfil-comportamental')

        if(!perfil){

            const result =  getEmptyStringIndices(questions)
            result.map((i, index) =>{
                localStorage.removeItem(`${index+1}`)
                responseQuestions(index, '')
            });
            
            // navigate('/')
           
        }
    },[])
    useEffect(()=>{
        if(tabIndex===4){
            const result =  getEmptyStringIndices(questions)
            if(result.length>0){
                setIsInvalid(true)
            }
        }else{
            setIsInvalid(false)
        }
    },[questions, tabIndex])
    useEffect(()=>{
        if(tabIndex>0){
            const result =  getEmptyStringIndices(questions)
            for (let index = tabIndex*5-5; index < tabIndex*5; index++) {                
                const x = result.filter((i)=>i===index)    
                if(x.length>0)            
                toast({
                    position: 'top',
                    description: `Questão de nº ${(x[0]+1)} não foi respondida!`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })                    
            }
        }
    },[tabIndex])

    const handleTabChange = (index: number) => {
        setTabIndex(index);
    };

    const handleBack = () => {
        if (tabIndex > 0) {
            setTabIndex(tabIndex - 1);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };
    const handleNext = () => {

        if (tabIndex < 4) { // Alterado para 4, pois o índice começa do zero
            setTabIndex(tabIndex + 1);
            scrollToTop()
        }else{
            if(tabIndex===4){
                const result =  getEmptyStringIndices(questions)
                    console.log('err',result)
                if(result.length>0){
                    for (let index = tabIndex*5-5; index <= tabIndex*5; index++) {                
                        const x = result.filter((i)=>i===index)    
                        if(x.length>0)            
                        toast({
                            position: 'top',
                            description: `Questão de nº ${(x[0]+1)} não foi respondida!`,
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        })                    
                    }
                }else{
                    Register()
                }
            }
        }
    };

    function getEmptyStringIndices(arr: string[]): number[] {
        const emptyIndices: number[] = [];
      
        for (let index = 0; index < arr.length; index++) {
          if (arr[index].length === 0) {
            emptyIndices.push(index);
          }
        }
      
        return emptyIndices;
      }     


    
    async function Register(){
        const result: string[] = Array.from({ length: 25 }, (_, i) =>
            localStorage.getItem(`${i + 1}`) as string
        );
        function contarOcorrenciasLetra(letra: string): number {
            return result.filter((opcao) => opcao === letra).length;
        }
        if(result && user && user.id !== null && user.id !== undefined)    {    
            try {
                await newRegisterTest({
                    userId: user.id,
                    i_influente: contarOcorrenciasLetra("I") * 4,
                    c_estavel: contarOcorrenciasLetra("C") * 4,
                    a_dominante: contarOcorrenciasLetra("A") * 4,
                    o_analitico: contarOcorrenciasLetra("O") * 4
                })
                navigate('/result')
            } catch (error) {
                
            }
        }else{
            alert(" user underfined")
        }
        
    }

    return (
        <Template title="">            
            <VStack maxW={900} w="full" mx="auto" px={2}>
                <Text textAlign={"center"} fontWeight={"400"}>
                    Em cada uma das 25 questões a seguir, escolha uma alternativa e marque-a no espaço correspondente.
                </Text>
                <HStack w="100%" mt={10} px={5}>
                    <Text fontWeight={"bold"}>{tabIndex*20}%</Text>
                    <Box w={"100%"} mx="auto">                    
                        <Progress value={tabIndex*20} size="md" rounded={5} colorScheme="blue" />
                    </Box>
                </HStack>
                
                <Tabs w="full" index={tabIndex} onChange={handleTabChange}>
                    <TabPanels>
                        <TabPanel>
                            <QuizOne />
                        </TabPanel>
                        <TabPanel>
                            <QuizTwo />
                        </TabPanel>
                        <TabPanel>
                            <QuizThree />
                        </TabPanel>
                        <TabPanel>
                            <QuizFour />
                        </TabPanel>
                        <TabPanel>
                            <QuizFive />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <Stack display='flex' w="100%" mt={5} mb={10} flexDirection={['column-reverse', 'row']}>
                    <Button
                        borderBottomLeftRadius={10}
                        leftIcon={<ArrowLeft size={20} />}
                        w="100%"
                        type="button"
                        isDisabled={tabIndex <= 0}
                        onClick={handleBack}
                        colorScheme="gray"
                    >
                        Voltar
                    </Button>
                    <Button
                        borderBottomRightRadius={10}
                        w="100%"
                        rightIcon={<ArrowRight size={20} />}
                        onClick={handleNext}
                        colorScheme="blue"
                    >
                        Próximo
                    </Button>
                </Stack>
            </VStack>
        </Template>
    );
}
