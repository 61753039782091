import React, { ReactNode, useState } from "react";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
  title?: string;
  home?: boolean;
  isPrint?: boolean;
}

export function Template({ children, title, home, isPrint }: Props) {
  const [isopen, setIsOpen] = useState(false);
  const location = useLocation();
  return (
    <VStack minH={"100vh"} bgColor={"#0D0D0D"} position="relative">
      {/* Efeito de luz azul no canto superior direito */}
      <Box
        position="absolute"
        top="0"
        right="0"
        width="1047px"
        height="212px"
        zIndex="0"
        filter="blur(80px)"
        background="rgba(11, 74, 125, 1)"
        opacity="0.3"
      ></Box>

      <Header sendInfo={() => setIsOpen(true)} isPrint={isPrint} />
      <Flex
        mt={20}
        w="100%"
        height={"full"}
        mx="auto"
        flexDirection={"column"}
        zIndex={99}
      >
        <Sidebar isOpen={isopen} sendInfo={() => setIsOpen(false)} />
        {!home &&
          location.pathname !== "/sign-in" &&
          location.pathname !== "/sign-up" && (
            <Box textAlign={"center"} mb={5}>
              {title ? (
                <Text
                  fontSize={"2xl"}
                  zIndex={2}
                  fontWeight={763}
                  lineHeight={"normal"}
                  letterSpacing={"0.54px"}
                >
                  {title}
                </Text>
              ) : (
                <Text
                  marginX="auto"
                  w={400}
                  fontSize={"4xl"}
                  fontWeight={763}
                  lineHeight={"normal"}
                  letterSpacing={"0.54px"}
                >
                  Teste de{" "}
                  <Text as="span" color="blue.400">
                    Perfil Comportamental
                  </Text>
                </Text>
              )}
            </Box>
          )}

        {children}
      </Flex>
    </VStack>
  );
}
