import { Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export function BodyContent({ children }: Props) {
  return (
    <Flex
      p={3}
      w="100%"
      alignItems="center"
      alignContent="center"
      gap="24px"
      alignSelf="stretch"
      flexWrap="wrap"
      borderRadius="8px"
      background="transparent"
    >
      {children}
    </Flex>
  );
}
