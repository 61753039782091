import React from "react";
import { Divider, VStack } from "@chakra-ui/react";
import { Quiz } from "../../componentes/quiz";
import { motion } from "framer-motion";

export function QuizFive() {
  
  return (
    <VStack maxW={900} mx="auto" px={2} align="flex-start">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{width: '100%'}}
      >
        <Quiz 
          number={21}
          title="Se eu fosse bilionário..."
          responseOne={{ value: "C", text: "Faria doações para muitas entidades" }}
          responseTwo={{ value: "O", text: "Criaria uma poupança avantajada" }}
          responseThree={{ value: "I", text: "Faria o que desse na cabeça" }}
          responseFour={{ value: "A", text: "Me exibiria bastante para algumas pessoas" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={22}
          title="Eu acredito que..."
          responseOne={{ value: "A", text: "O destino é mais importante que a jornada" }}
          responseTwo={{ value: "C", text: "A jornada é mais importante que o destino" }}
          responseThree={{ value: "O", text: "Um centavo economizado é um centavo ganho" }}
          responseFour={{ value: "I", text: "Bastam um navio e uma estrela para navegar" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={23}
          title="Eu acredito também que..."
          responseOne={{ value: "A", text: "Aquele que hesita está perdido" }}
          responseTwo={{ value: "O", text: "De grão em grão a galinha enche o papo" }}
          responseThree={{ value: "C", text: "O que vai, volta" }}
          responseFour={{ value: "I", text: "Um sorriso ou uma careta é o mesmo para quem é cego" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={24}
          title="Eu acredito ainda que..."
          responseOne={{ value: "O", text: "É melhor prudência do que arrependimento" }}
          responseTwo={{ value: "I", text: "A autoridade deve ser desafiada" }}
          responseThree={{ value: "A", text: "Ganhar é fundamental" }}
          responseFour={{ value: "C", text: "O coletivo é mais importante do que o individual" }}
        />
        <Divider my={5} w="100%" />
        <Quiz 
          number={25}
          title="Eu penso que..."
          responseOne={{ value: "I", text: "Não é fácil ficar encurralado" }}
          responseTwo={{ value: "O", text: "É preferível olhar antes de pular" }}
          responseThree={{ value: "C", text: "Duas cabeças pensam melhor do que uma" }}
          responseFour={{ value: "A", text: "Se você não tem condições de competir, não compita" }}
        />
      </motion.div>
    </VStack>  
  );
}
