import { Route, Routes } from "react-router-dom";
import { LandingPage } from "../pages/landingPage";
import { Result } from "../pages/result";
import { TabQuiz } from "../pages/quiz/tabQuiz";
import { Pdf } from "../pages/result/pdf";
import SignIn from "../pages/signIn";
import SignUp from "../pages/signUp";
import { HomePage } from "../pages/homePage";
import { MyTests } from "../pages/myTests";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import { AdminHomePage } from "../pages/admin/homePage";
import { ResultAdmin } from "../pages/result/resultAdmin";

export function Router(){
  
    return(
        <Routes>
            <Route path="/" element={<PublicRoute><LandingPage /></PublicRoute>} /> 
            <Route path="/sign-in" element={<PublicRoute><SignIn /></PublicRoute>} />
            <Route path="/sign-up" element={<PublicRoute><SignUp /></PublicRoute>} /> 

            <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
            <Route path="/my-tests" element={<PrivateRoute><MyTests /></PrivateRoute>} />

            <Route path="/questions" element={<PrivateRoute><TabQuiz /></PrivateRoute>} />
            <Route path="/result"  element={<PrivateRoute><Result /></PrivateRoute>} />

            <Route path="/result/pdf" element={<PrivateRoute><Pdf /></PrivateRoute>} />

            <Route path="/admin/home" element={<PrivateRoute><AdminHomePage /></PrivateRoute>} />
            <Route path="/admin/result"  element={<PrivateRoute><ResultAdmin /></PrivateRoute>} />
         </Routes>
        
    )
}