import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { IAuthProvider, IContext, IFormUser, INewUser, IUser } from "./types";
import { useToast } from "@chakra-ui/react";
import { LoginRequest, createUser, findManyUsers, getUserLocalStorage, setUserLocalStorage } from "./util";

export const AuthContext = createContext<IContext>({} as IContext)

export const AuthProvider = ({ children }: IAuthProvider) => {
  const navigate = useNavigate();
  const toast = useToast()
  const [user, setUser] = useState<IUser | null>(null)  

  const [totalUsers, setTotalUsers ] = useState(0)
  const [users, setUsers] = useState<IUser []>([]) 

  useEffect(()=>{
    const result = getUserLocalStorage()
    if(result)
    setUser(result.user)
  },[ , navigate])

  function verifyToken(){
    const token = JSON.stringify(Cookies.get("@PerfilComportamental-Token")) || null

    if(token){
      return true
    }
    return false
  }

  async function authenticate(email: string, password: string) {
    await LoginRequest(email, password)
    .then((response) => {      
      setUserLocalStorage(response)
      const res = response.user
      setUser(res)
      toast({
        position: 'top',
        description: response.msg,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    })
    .catch((error)=>{
      toast({
        position: 'top',
        description: error.response.data.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    })    
    
  }


  async function novoUser(user: INewUser) {       
    await createUser(user)
    .then((response => {
      toast({
        position: 'top',        
        description: "Usuário criado com sucesso!",
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    }))
    .catch(error=>{
      console.log(error)
      toast({
        position: 'top',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    })        
  }
  function logout() {    
    toast({
      position: 'top',
      description: "Sua sessão encerrou!",
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
    setUser(null)
    Cookies.remove('@PerfilComportamental-Data')
    Cookies.remove('@PerfilComportamental-Token')
    navigate('/')
  }
  const checkAuth = async () => {
    const result = getUserLocalStorage()
    if(result)
    setUser(result.user)
  };
  async function searchUser(user: IFormUser) {       
    await findManyUsers(user)
    .then((response) => {
      setUsers(response.users)
      setTotalUsers(response.total)
    })
    .catch(error=>{
      toast({
        position: 'top',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    })        
  }


  return (
    <AuthContext.Provider value={{ 
      authenticate,
      logout,
      novoUser,
      user,
      setUser,
      checkAuth,
      searchUser,
      users,
      totalUsers
    }}>
      {children}
    </AuthContext.Provider>
  )
}