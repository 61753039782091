import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { inputAnatomy } from "@chakra-ui/anatomy";
import {
  background,
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/react";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";

// Importe a fonte Montserrat
import "typeface-montserrat";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
);

// Função para definir o valor do LocalStorage
const setColorModeLocalStorage = (colorMode: string) => {
  localStorage.setItem('chakra-ui-color-mode', colorMode);
};

// Função para definir o valor do Cookie
const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Expiração em dias
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

// Função para obter o valor do Cookie
const getCookie = (name: string) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

// Função para remover o Cookie
const removeCookie = (name: string) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

// Função para obter o modo inicial a partir do LocalStorage ou Cookie
const getInitialColorMode = () => {
  const savedColorMode = localStorage.getItem('chakra-ui-color-mode') || getCookie('chakra-ui-color-mode');
  return savedColorMode ? savedColorMode : 'dark'; // Se não houver valor, define 'dark'
};

// Define o valor inicial do modo como 'dark' no LocalStorage e Cookie
setColorModeLocalStorage('dark');
setCookie('chakra-ui-color-mode', 'dark', 7); // Define cookie por 7 dias

// Crie um tema estendido com a fonte Montserrat
const theme = extendTheme({
  components: {
    Input: {
      variants: {
        outline: {
          field: {
            rounded: "12px",
            border: "1px solid rgba(255, 255, 255, 0.20)", // Borda padrão

            background: "rgba(255, 255, 255, 0.10)",
            h: "50px",

            _focus: {
              background: "rgba(255, 255, 255, 0.10)", // Mantém a cor de fundo no foco
              borderColor: "rgba(255, 255, 255, 0.30)",
            },
          },
        },
      },
      defaultProps: {
        variant: "outline", // Define o "variant" padrão como outline
      },
    },
  },
  colors: {
    blue: {
      "400": "#0B4A7D",
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Montserrat, sans-serif",
  },
  config: {
    initialColorMode: getInitialColorMode(), // Define o tema com base no valor armazenado
    useSystemColorMode: false,  // Impede que o tema seja alterado automaticamente pelo sistema
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
