import React from "react"
import { Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { NavSection } from "./NavSection";
import { NavLink } from "./NavLink";
import { RiDashboardLine, RiHomeLine } from "react-icons/ri";



export function SidebarNav() {
  const {user} = useAuth()
  const navigate = useNavigate()



  return (
    <Stack  align='flex-start' >
      {user?.is_admin
      ?
      <NavSection title='MENU'>
        <NavLink icon={RiHomeLine} href='/admin/home'><Text>Início</Text></NavLink>
        <NavLink icon={RiDashboardLine} href='/admin/home'><Text>Criações</Text></NavLink>
      </NavSection>
      :
      <NavSection title='MENU'>
        {/* <NavLink icon={RiHomeLine} href='/home'><Text>Início</Text></NavLink> */}
        <NavLink icon={RiDashboardLine} href='/my-tests'><Text>Meus testes</Text></NavLink>
      </NavSection>
      }      
    </Stack >
  )
}